.btn {
    --#{$prefix}btn-padding-x: 16px;
    --#{$prefix}btn-padding-y: 8px;
    --#{$prefix}btn-font-size: 14px;
}

.btn-primary {
    --#{$prefix}btn-bg: #{$btn-primary-bg};
    --#{$prefix}btn-border-color: #{$btn-primary-bg};
    --#{$prefix}btn-hover-bg: #{$btn-primary-hover-bg};
    --#{$prefix}btn-hover-border-color: #{$btn-primary-hover-bg};
    --#{$prefix}btn-active-bg: #{$btn-primary-hover-bg};
    --#{$prefix}btn-active-border-color: #{$btn-primary-hover-bg};
    --#{$prefix}btn-disabled-bg: #{$btn-primary-bg};
    --#{$prefix}btn-disabled-border-color: #{$btn-primary-bg};
}

.btn-success {
    --#{$prefix}btn-bg: #{$btn-success-bg};
    --#{$prefix}btn-border-color: #{$btn-success-bg};
    --#{$prefix}btn-hover-bg: #{$btn-success-hover-bg};
    --#{$prefix}btn-hover-border-color: #{$btn-success-hover-bg};
    --#{$prefix}btn-active-bg: #{$btn-success-hover-bg};
    --#{$prefix}btn-active-border-color: #{$btn-success-hover-bg};
    --#{$prefix}btn-disabled-bg: #{$btn-success-bg};
    --#{$prefix}btn-disabled-border-color: #{$btn-success-bg};
}

.btn-danger {
    --#{$prefix}btn-bg: #{$btn-danger-bg};
    --#{$prefix}btn-border-color: #{$btn-danger-bg};
    --#{$prefix}btn-hover-bg: #{$btn-danger-hover-bg};
    --#{$prefix}btn-hover-border-color: #{$btn-danger-hover-bg};
    --#{$prefix}btn-active-bg: #{$btn-danger-hover-bg};
    --#{$prefix}btn-active-border-color: #{$btn-danger-hover-bg};
    --#{$prefix}btn-disabled-bg: #{$btn-danger-bg};
    --#{$prefix}btn-disabled-border-color: #{$btn-danger-bg};
}

.btn-light {
    --#{$prefix}btn-color: #{$body-color};
    --#{$prefix}btn-bg: #{$btn-light};
    --#{$prefix}btn-border-color: #{$btn-light};
    --#{$prefix}btn-hover-color: #{$body-color};
    --#{$prefix}btn-hover-bg: #{$btn-light-hover-bg};
    --#{$prefix}btn-hover-border-color: #{$btn-light-hover-bg};
    --#{$prefix}btn-active-color: #{$body-color};
    --#{$prefix}btn-active-bg: #{$btn-light-hover-bg};
    --#{$prefix}btn-active-border-color: #{$btn-light-hover-bg};
    --#{$prefix}btn-disabled-color: #{$body-color};
    --#{$prefix}btn-disabled-bg: #{$btn-light};
    --#{$prefix}btn-disabled-border-color: #{$btn-light};
}

.btn-outline-primary {
    --#{$prefix}btn-color: #{$btn-primary-bg};
    --#{$prefix}btn-border-color: #{$btn-primary-bg};
    --#{$prefix}btn-hover-bg: #{$btn-primary-bg};
    --#{$prefix}btn-hover-border-color: #{$btn-primary-bg};
    --#{$prefix}btn-active-bg: #{$btn-primary-bg};
    --#{$prefix}btn-active-border-color: #{$btn-primary-bg};
    --#{$prefix}btn-disabled-color: #{$btn-primary-bg};
    --#{$prefix}btn-disabled-border-color: #{$btn-primary-bg};
}

// .btn-primary,
// .btn-success,
// .btn-danger {
//     app-svg-icon {
//         background-color: #FFFFFF;
//     }
// }

.btn {
    &:has(app-svg-icon) {
        display: inline-flex;
        gap: 10px;
        align-items: center;
    }

    app-svg-icon {
        vertical-align: bottom;
        background-color: var(--#{$prefix}btn-color);
    }
}