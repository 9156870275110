:root {

    // body
    --#{$prefix}body-font-family: Roboto, 'Khmer';
    --#{$prefix}body-color: #{$body-color};
    --#{$prefix}body-font-size: 14px;

    // link
    --#{$prefix}link-color: #{$primary};
    --#{$prefix}link-hover-color: #{$primary};

    // background color
    --#{$prefix}primary-rgb: #{$primary-rgb};

    // border
    --#{$prefix}light-rgb: 217, 217, 217;
    --#{$prefix}border-radius: 0.625rem;



    // KAS assets
    --primary: #{$primary};
    --primary-bg: #{$btn-primary-bg};

    --btn-light: #{$btn-light};

    --icon-warning: #{$icon-warning};
    --icon-danger: #{$icon-danger};

    --sidenav-width: #{$offcanvas-horizontal-width};
    --navbar-height: 70px;

    --logo-size: 75px;
    --avatar-size: 40px;

    --status-pending: #{$status-pending};
    --status-appeal: #{$status-appeal};
}

@font-face {
    font-family: "Khmer";
    src: url("/assets/fonts/KHMEROSBATTAMBANG.TTF") format("truetype");
    font-weight: normal;
    font-style: normal;
}