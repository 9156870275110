.custom-photo-preview {
    .modal-content {
        background: #000;
    }

    .btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        background-color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
    }

    .modal-body {
        padding: 0;

        .carousel {
            .carousel-item {
                transition: none;
            }
        }
    }

    .picsum-img-wrapper {
        height: 100vh;
    }

    img.gallery {
        height: 100%;
        width: 100%;
        max-height: 50vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        border-radius: 0;

        @media screen and (min-width: 992px) {
            max-height: 100%;
            max-width: 70%;
        }
    }
}