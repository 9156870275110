/* You can add global styles to this file, and also import other style files */

// structure
@import './custom/structure/layouts';
@import './custom/structure/page-head';
@import './custom/structure/general';

// components
@import './custom/components/text-truncate';

// shared
@import './custom/shared/product-card';
@import './custom/shared/ngb-date-range-picker';
@import './custom/shared/photo-preview';
@import './custom/shared/reported-content';

// pages
@import './custom/pages/app';
@import './custom/pages/post';
@import './custom/pages/dashboard';
@import './custom/pages/report-content-activities';
@import './custom/pages/kas-ai-market-trend';
@import './custom/pages/bank-management';

// account
@import './custom/account/login';


// custom -> plugin
@import './custom/plugin/ng-select';
@import './custom/base/base';
// @import "./custom/themes/custom-material";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";