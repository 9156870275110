/* Core files */
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";
@import "root";



@import "custom/components/buttons";
@import "custom/components/dropdown";
@import "custom/components/grid";
@import "custom/components/badge";
@import "custom/components/form-control";
@import "custom/components/table";
@import "custom/components/modal";
@import "custom/components/border";
@import "custom/components/heading";
@import "custom/components/accordion";
@import "custom/components/carousel";