@import "../../variables";

.form-control {
    color: rgba(0, 0, 0, 0.70);
    font-size: inherit;

    &:focus {
        color: rgba(0, 0, 0, 0.70);
        box-shadow: 0 0 0 0.15rem rgba($color: $primary, $alpha: 0.25);
    }

    &.is-invalid {
        &:focus {
            box-shadow: 0 0 0 0.15rem rgba($color: $invalid, $alpha: 0.25);
        }
    }
}