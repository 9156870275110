.noun-drop-down-container {
    position: relative;

    .noun-drop-down {
        width: 120px;
        height: 120px;
        cursor: pointer;
        position: relative;

        .remove-icon {
            position: absolute;
            right: -10px;
            top: -10px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
        }

        .btn-close {
            filter: brightness(0) saturate(100%) invert(15%) sepia(79%) saturate(3201%) hue-rotate(354deg) brightness(106%) contrast(87%);
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 12px;
            border: 1px dotted rgba($color: #000, $alpha: .1);
            background-color: #fff;

            &.is-invalid {
                outline: var(--bs-form-invalid-border-color) dashed 1px;
            }
        }
    }
}