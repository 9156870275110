.reported-content {

    &.post-detail,
    &.product-report-detail,
    &.buy-listing-report-detail,
    &.shop-report-detail {

        .carousel-container {
            width: 400px;
            height: 270px;

            ngb-carousel {
                width: 100%;
                height: 100%;

                .carousel-inner {
                    height: 100%;

                    .carousel-item {
                        height: 100%;

                        .picsum-img-wrapper {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .left-side {
            .card {
                background-color: var(--btn-light);
                border: none;
            }
        }

        .right-side {
            .summery-item {
                background-color: rgb(var(--color) / 0.10);
                color: rgb(var(--color));
                border-left: 4px solid rgb(var(--color));
            }
        }
    }

    .product-report-detail-preview,
    .buy-listing-report-detail-preview {
        .info {
            min-width: 250px;
            width: calc(100% - 400px - 16px);
        }
    }

    .post-detail-reporter,
    .product-report-detail-reporter,
    .buy-listing-report-detail-reporter,
    .shop-report-reporter-list {
        overflow: auto;
    }
}

.report-type-label {
    background-color: rgb(var(--color) / 0.10);
    color: rgb(var(--color));
}

.no-data {
    height: calc(100vh - var(--navbar-height) - 164px)
}

.shop-report-preview,
.user-profile {
    .profile-container {
        position: relative;
        text-align: center;

        .profile-content {
            display: flex;
            flex-direction: column;

            .profile-absolute {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .profile-shrink {
                height: 60px;
            }
        }
    }
}

.reporter-detail-modal {
    .content-right {
        img.detail-photo-url {
            object-fit: contain;
        }
    }
}

@media screen and (min-width: 576px) {
    .reporter-detail-modal {
        .content-left {
            padding-right: 43px;
        }

        .content-right {
            padding-left: 43px;
            border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
        }
    }
}

@media screen and (min-width: 992px) {

    .shop-report-preview,
    .user-profile {
        .profile-container {
            text-align: start;

            .profile-content {
                flex-direction: row;
                align-items: flex-end;
                gap: 20px;

                .profile-absolute {
                    left: 24px;
                    transform: translateY(-50%);
                }

                .profile-shrink {
                    height: 0;
                }
            }
        }
    }
}