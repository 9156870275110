$avatar-s: 32px;
$avatar-md: 48px;
$avatar-lg: 64px;
$avatar-xl: 120px;

// .search-box {
//     position: relative;
//     display: flex;
//     align-items: center;

//     .prefix-icon {
//         position: absolute;
//         display: flex;
//         left: 22px;
//     }

//     input {
//         background-color: var(--btn-light);
//         padding: 8px 16px 8px 54px;
//     }

//     .reset {
//         position: absolute;
//         right: 28px;

//         &::after {
//             content: "\00d7";
//             display: inline-block;
//             font-size: 28px;
//             cursor: pointer;
//             padding: 0px 5px;
//         }
//     }
// }

.search-box {
    position: relative;

    .prefix-icon {
        z-index: 1050;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 7px;
    }

    .ng-select {
        .ng-select-container {
            .ng-value-container {
                padding-left: 2.25rem;
            }
        }
    }
}

html,
body {
    height: 100%;
}

// image
img {
    object-fit: cover;
    object-position: center;
}

// avatar profile
.avatar {
    border-radius: 50%;
    object-position: top;

    &__sm {
        width: $avatar-s;
        height: $avatar-s;
        min-width: $avatar-s;
        min-height: $avatar-s;
    }

    &__md {
        width: $avatar-md;
        height: $avatar-md;
        min-width: $avatar-md;
        min-height: $avatar-md;
    }

    &__lg {
        width: $avatar-lg;
        height: $avatar-lg;
        min-width: $avatar-lg;
        min-height: $avatar-lg;
    }

    &__xl {
        width: $avatar-xl;
        height: $avatar-xl;
        min-width: $avatar-xl;
        min-height: $avatar-xl;
    }
}

// gallery
.gallery {
    border-radius: 10px;
    overflow: hidden;
    height: var(--height, 250px);
    background-color: var(--background-color);
}

// form
.detail-form {
    .main-label {
        font-weight: 700;
        color: #000000;
        margin-bottom: 0.5rem;
    }

    .form-value {
        color: #000000;
    }
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: var(--bs-body-color) !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--bs-body-color) !important;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--bs-body-color) !important;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--bs-body-color) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--bs-body-color) !important;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: var(--bs-body-color) !important;
}

// filter block
.filter-block {
    background-color: var(--btn-light);

    .search-form-control {
        border: none;
        background-color: rgba($color: #D9D9D9, $alpha: 0.05);

        &:focus {
            box-shadow: none;
        }
    }
}