// body
$body-color: #979797;

// border
$border-color: rgba($color: #000000, $alpha: 0.1);

// text
$primary: #006879;
$primary-rgb: 0, 104, 121;


// button
$btn-primary-bg: #003640;
$btn-primary-hover-bg: #003039;
$btn-success-bg: #1ACA22;
$btn-success-hover-bg: #18d520;
$btn-danger-bg: #F53434;
$btn-danger-hover-bg: #f71f1f;
$btn-light: rgba($color: $body-color, $alpha: 0.05);
$btn-light-hover-bg: #f1f1f1;

$primary-bg-subtle: tint-color($primary, 80%);

// spacer
$spacer: 20px;
$spacers: (
    0: 0,
    1: 4px,
    2: 8px,
    3: 10px,
    4: 12px,
    5: 16px,
    6: 20px,
    7: 24px,
);

// table
$table-variants: (
    "primary": $btn-primary-bg
);

// icon
$icon-warning: #FFCB73;
$icon-danger: #DC454E;

// status
$status-pending: #FFC120;
$status-appeal: #28BCFC;
$status-approved: $btn-success-bg;
$status-violated: $btn-danger-bg;

// card
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-border-color: $border-color;

// modal
$modal-inner-padding: 20px;


// Heading
$headings-font-weight: 700;

// offcanvas
$offcanvas-horizontal-width: 300px;

// form
$invalid: #fd625e;
$form-invalid-border-color: $invalid;

// dropdown
$dropdown-link-active-color: inherit;
$dropdown-link-active-bg: #F8F9Fa;

$dropdown-item-padding-y: 0.35rem;

//color 
$color-secondary:#006879;;

//font 
$fs-normal: 14px;