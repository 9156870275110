.dropdown-menu {
    // --#{$prefix}dropdown-link-active-bg: #006879;

    .dropdown-item {
        display: flex;
        align-items: center;

        app-svg-icon {
            --size: 20px;
            margin-right: 1rem;

            &.logout {
                background-color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
            }
        }
    }
}