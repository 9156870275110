@import '../../variables';

$menu-item: 50px;

.main-layout {
    .wrapper {
        .navbar-toggler {
            width: 36px;
            height: 36px;
            position: fixed;
            left: 0;
            top: calc(var(--navbar-height) / 2);
            transform: translate(24px, -50%);
            z-index: 1001;
        }

        .drawer-sidebar {
            position: fixed;
            width: var(--sidenav-width);
            height: 100%;
            transition: transform 0.2s ease-in-out;
        }
    }

    // app-navbar
    .navbar-container {
        position: fixed;
        height: var(--navbar-height);
        width: calc(100vw - var(--sidenav-width) - 48px);
        z-index: 1000;
        background-color: #FFFFFF;
        margin-left: calc(var(--sidenav-width) + 24px);

        .user-bar {

            .lang-item {
                &.active::after {
                    content: url('/assets/icons/check-circle-18.svg');
                    width: 18px;
                    height: 18px;
                }
            }

            .user-dropdown {
                font-size: 18px;

                &:hover {
                    background-color: unset;
                    color: var(--primary-bg);
                }

                img {
                    width: var(--avatar-size);
                    height: var(--avatar-size);
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .drawer-sidebar {
            transform: translateX(calc(var(--sidenav-width) * -1));
        }

        .navbar-container {
            width: calc(100vw - 48px);
            margin: 0 24px;
        }

        .app-container {
            margin-left: 0;
        }
    }
}

// app-sidebar
.sidebar-container {
    padding-left: calc(var(--bs-gutter-x) * 0.5);

    .logo-box {
        img {
            width: var(--logo-size);
            height: var(--logo-size);
        }
    }

    .menu-container {
        .menu-item {
            cursor: pointer;
            font-size: 16px;

            .layer-menu {
                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }

                &.accordion-children {
                    padding-left: calc(16px + 24px + 10px);
                }
            }

            &.active {
                color: var(--primary);

                .layer-menu {
                    background-color: rgba($color: $primary, $alpha: 0.05);

                    app-svg-icon {
                        background-color: var(--primary);
                    }
                }
            }
        }

        .layer-menu,
        .accordion-button {
            height: $menu-item;
            padding: 0;
        }

        .accordion {
            .accordion-header {
                &.active {

                    .accordion-button {
                        color: var(--primary);

                        &[aria-expanded=false] {
                            background-color: rgba($color: $primary, $alpha: 0.05);
                        }

                        app-svg-icon {
                            background-color: var(--primary);
                        }
                    }
                }
            }

            .accordion-button {
                &:focus {
                    box-shadow: none;
                }

                &:not(.collapsed) {
                    background-color: inherit;
                    color: inherit;
                    box-shadow: none;
                    border-radius: 10px;
                }

                .collapse-title {
                    padding: 0 16px;
                }
            }

            .accordion-body {
                padding: 0;
            }
        }
    }
}