.app-container {
    margin-left: var(--sidenav-width);
    padding-top: var(--navbar-height);
    transition: all 0.2s ease-in-out;

    .app-content {
        max-width: calc(1920px - var(--sidenav-width));
        padding: 16px 24px;
        margin: auto;
    }

    .app-header {
        .title {
            font-size: 16px;
            font-weight: 700;
            color: var(--primary-bg);

            &.return-back {
                display: flex;
                width: fit-content;
                cursor: pointer;

                &::before {
                    content: "";
                    -webkit-mask-image: url("/assets/icons/arrow-back.svg");
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    -webkit-mask-size: cover;
                    -webkit-mask-position: center;
                    -webkit-mask-repeat: no-repeat;
                    background-color: var(--primary-bg);
                }
            }
        }
    }
}