$card-image-size: 51px;

.kas-ai-market-trend {

    .section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 15px;
        justify-content: center;

        &.general {
            font-size: 16px;
        }
    }

    .card-image {
        width: $card-image-size;
        height: $card-image-size;
        min-width: $card-image-size;
        min-height: $card-image-size;
    }

    .statistics {
        .label-item {
            font-size: 16px;
        }
    }
}