.report-activities {
    
    &__container {
        display: flex;
        flex-direction: column;
        gap: 29px;
    }

    &__list {
        gap: 12px;
    }

    .vr {
        position: absolute;
        left: -28px;
        top: -12px;
        transform: translateX(-50%);
        height: calc(100% + 24px);
    }

    .info-card {
        margin-left: 44px;

        img {
            height: 70px;
        }
    }

    .input-response {
        background-color: inherit;

        &:focus {
            box-shadow: none;
        }
    }
}