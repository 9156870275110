.disable-input{
    input:disabled {
        cursor: not-allowed !important;
        background-color: white !important;
        border: 1px solid transparent !important;
        outline: none !important;
        box-shadow: none !important;
      }
}
.mdc-select--disabled,
.mat-mdc-select-disabled {
    cursor: not-allowed !important;

    .mdc-select__dropdown-icon,
    .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }

    .mdc-select__selected-text,
    .mat-mdc-select-value {
      pointer-events: none;
      background-color: white !important;
      color: black !important;
      border: 1px solid transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .mdc-select--disabled:focus,
  .mat-mdc-select-disabled:focus,
  .mdc-select__anchor:focus,
  .mat-mdc-select-trigger:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid transparent !important;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
    background-color: transparent !important;
  }
  .mat-mdc-select-placeholder {
    font-family: 'Khmer', sans-serif !important;
    font-size: 14px;
  }