.login-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(90deg, #003640 0%, #006879 100%);
    color: #fff;

    .side-container {
        &.left-side {
            background-image: url('/assets/images/babalolo.png');
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;

            &>* {
                display: none;
            }
        }

        &.right-side {

            .top-header {
                display: none;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: calc(100% - 40px);
                max-width: 430px;
                padding: 50px 20px;
                background-color: #003640;
                border-radius: 10px;
                transition: transform .2s ease-in-out;

                .section-header {
                    margin-bottom: 40px;
                }
            }

            .btn-submit {
                width: 100%;
                height: 54px;
                max-width: 379px;
                margin: auto;
                margin-top: 40px;
            }
        }
    }
}

@media screen and (min-width: 920px) {
    .login-container {
        display: flex;

        .side-container {
            &.side {
                width: 50%;
            }

            &.left-side {
                display: flex;
                flex-direction: column;
                place-content: center;
                align-items: center;
                gap: 20px;

                &>* {
                    display: block;
                }

                img {
                    width: 350px;
                    height: 350px;
                }
            }

            &.right-side {
                padding: 3rem;
                background-image: url('/assets/icons/login-rectangle.svg');

                .top-header {
                    display: block;
                }

                .content {
                    background-color: unset;
                    right: 0;
                    transform: translate(0px, -50%);
                    width: unset;
                    max-width: 100%;
                    padding: 50px 70px;

                    .form-container {
                        width: 75%;
                        margin: auto;

                        .section-header {
                            display: none;
                        }
                    }
                }
            }
        }
    }

}