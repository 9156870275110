@import "../../variables";

$post-photo-width: 150px;
$post-photo-height: 150px;
$post-photo-xl-height: 100px;

.product-card {
    text-decoration: none;

    .card-info {
        width: calc(100% - 48px);

        .product-title {
            height: 21px;
        }

        .card-description {
            height: 42px;
        }
    }

    .post-photo {
        width: $post-photo-width;
        height: $post-photo-height;
        min-width: $post-photo-width;
        min-height: $post-photo-height;
    }

    .status {
        padding: var(--bs-badge-padding-x);

        &.pending {
            background: rgba($color: $status-pending, $alpha: 0.10);
            color: var(--status-pending);
        }

        &.appeal {
            background: rgba($color: $status-appeal, $alpha: 0.10);
            color: $status-appeal;
        }

        &.approved {
            background: rgba($color: $status-approved, $alpha: 0.10);
            color: $status-approved;
        }

        &.violated {
            background: rgba($color: $status-violated, $alpha: 0.10);
            color: $status-violated;
        }
    }
}

@media screen and (min-width: 1200px) {
    .content-item-card {
        width: 100%;
    }

    .product-card {
        .card-body {
            width: calc(100% - 150px - 40px - 20px);
            border-bottom: none !important;
        }
    
        .post-photo {
            height: $post-photo-xl-height;
            min-height: $post-photo-xl-height;
            border-radius: 10px;
            border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
        }
    }
}