.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
    background-color: rgb(0, 54, 64);
    color: white;
}

.custom-day.faded {
    background-color: rgba(0, 54, 64, 0.5);
}

.control-date-picker {
    background: none;
    border: none;
}

.custom-date-range {
    display: flex;
    align-items: center;
    height: inherit;

    .date-range-input {
        position: relative;
        overflow: hidden;
        max-width: calc(50% - 4px);
        height: inherit;
    }

    input {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
        background: inherit;

        &:focus-visible {
            outline: none;
        }

        &:focus {
            box-shadow: none;
            background-color: inherit;
        }
    }

    .date-range-input-mirror {
        -webkit-user-select: none;
        user-select: none;
        visibility: hidden;
        white-space: nowrap;
        display: inline-block;
        min-width: 100px;
        height: inherit;
    }
}

ngb-datepicker.dropdown-menu {
    overflow: hidden;
}