$size: 64px;

.dashboard {

    .section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        justify-content: center;

        &.general {
            .card-image {
                width: $size;
                height: $size;
                min-width: $size;
                min-height: $size;
            }
    
            .title {
                font-size: 16px;
            }
        }

        &.users {
            .card {
                grid-column: 1 / span 2;

                ul {
                    li {
                        color: rgba(64, 64, 64, 0.90);
                        font-size: 16px;

                        &::marker {
                            color: var(--color, var(--bs-body-color));
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
.dashboard {
    .section {
      grid-template-columns: 1fr;
    }
 }
}