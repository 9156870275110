@import "../../variables";

.ng-select {
    .ng-select-container {
        border-radius: 0.375rem;
        border: 1px solid var(--bs-border-color);

        .ng-value-container {
            .ng-value {
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 0 !important;
                }
            }
        }
    }

    &.is-invalid {
        .ng-select-container {
            border: 1px solid $invalid;
        }
    }
}