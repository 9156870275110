.carousel {
    background: #000;

    .carousel-control-prev {
        &:hover,
        &:focus {
            left: -4px;
            transition: left 0.2s linear;
        }
    }

    .carousel-control-next {
        &:hover,
        &:focus {
            right: -4px;
            transition: right 0.2s linear;
        }
    }

    img {
        object-fit: contain;
    }
}