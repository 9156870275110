.table {
    min-width: var(--min-width, 500px);
    border-collapse: separate;
    border-spacing: 0 10px;

    &> :not(caption)>*>* {
        padding: 8px 16px;
        border-top-width: var(--bs-border-width);
        vertical-align: middle;
    }

    tr {

        th,
        td {
            height: 58px;
            max-width: 16vw;

            &:first-of-type {
                border-radius: 10px 0 0 10px;
            }

            &:last-of-type {
                border-radius: 0 10px 10px 0;
            }
        }
    }

    thead {
        tr {
            th {
                border-bottom: none;
            }
        }
    }

    tbody {
        tr {
            td {
                &:first-of-type {
                    border-left-width: var(--bs-border-width);
                }

                &:last-of-type {
                    border-right-width: var(--bs-border-width);
                }
            }
        }
    }
}

table {
    &.info-table {
        border-collapse: separate;
        border-spacing: 0 6px;

        tr {
            td:first-child {
                width: max-content;
                display: flex;
                align-items: center;
                
                app-svg-icon {
                    margin-right: 5px;
                }
            }
        }
    }
}